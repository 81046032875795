
const config = {
  steps: [
    "Abby is Listening...",
    "Gathering History...",
    "Thinking...",
    "Analyzing...",
    "Building Plan...",
  ]
}

export default config;