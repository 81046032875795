import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout, Modal, Progress, theme } from "antd";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import AnimationBG from "../pages/Home/Partials/AnimationBG";
import PayModal from "../pages/Home/Partials/PayModal";
import { MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SideBar from "./partials/SideBar";
// import Settings from "./partials/Settings";
import UserMenu from "./partials/UserMenu";
import LogoSrc from "../../assets/images/Abby_3x.png";
import smLogoSrc from "../../assets/images/Abby_3x.png";
import config from "../../config";
import constants from "../../config/constants";
import { setDarkMode, setOpenPayModal, setSiderCollapsed } from "../../redux/app/appSlice";
import { getPlans } from "../../redux/plan/planSlice";

// const { useToken } = theme;
const { Header, Sider } = Layout;

const stripePromise = loadStripe(constants.stripePK);

function AuthLayout({ children }) {
  // const { token } = useToken();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  // const isDarkMode = useSelector((state) => state.app.isDarkMode);
  const openPayModal = useSelector((state) => state.app.openPayModal);
  // const user = useSelector((state) => state.auth.user);
  const plans = useSelector((state) => state.plan.plans ?? []);
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const isPayed = useSelector((state) => state.auth.user.activeSubscription && (state.auth.user.activeSubscription.status == 'active' || state.auth.user.activeSubscription.status == 'trialing'));
  const totalMessages = useSelector((state) => state.app.totalMessages - 1);
  const step = useSelector((state) => parseInt((state.app.totalMessages - 1) / 8));

  const [hasSider, setHasSider] = useState(false);

  // const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getPlans());
    if (plans.length === 0) {
    }
  }, []);

  useEffect(() => {
    if ((location.pathname == '/voice-playground' || location.pathname == '/playground')) {
      setHasSider(true);
      if (isMobile) {
        dispatch(setSiderCollapsed(true));
      }
    } else {
      setHasSider(false);
    }
  }, [location]);

  /*   const changeTheme = () => {
      dispatch(setDarkMode());
    }; */

  /*   const onClose = () => {
      setOpen(false);
    }; */

  return (
    <Layout className={classNames("min-h-screen", location.pathname == '/voice-playground' && "bg-[#0c111b]")} hasSider={hasSider}>
      {hasSider && <SideBar />}
      <Layout className={classNames((hasSider && !siderCollapsed && !isMobile) && "ms-72", location.pathname == '/voice-playground' && "bg-[#0c111b]")}>
        <Header
          className={classNames(`flex items-center justify-center shadow-lg sticky pr-4 top-0 z-[998]`, siderCollapsed ? "pl-8" : "pl-0", location.pathname == '/voice-playground' ? "bg-transparent" : "bg-primary")}
        >
          {(hasSider && siderCollapsed) && <Button className="!text-white absolute top-4 left-3" onClick={() => {
            dispatch(setSiderCollapsed(!siderCollapsed));
          }} icon={siderCollapsed ? <MenuOutlined /> : <MenuOutlined />} type="text" />}
          <div className={classNames("flex items-center px-2 flex-1", isMobile ? 'justify-center' : 'justify-between')}>
            {!isMobile && <div className="flex items-center">
              <div
                className={classNames(
                  "demo-logo h-[64px]"
                  // !isDarkMode && "bg-white"
                )}
              >
                <Link to="/playground" className="hidden sm:inline">
                  <img
                    src={LogoSrc}
                    alt="logo"
                    className={classNames(
                      "w-[96px] p-3 text-white"
                      // !isDarkMode && "invert"
                    )}
                  />
                </Link>
                <Link to="/playground" className="inline sm:hidden">
                  <img
                    src={smLogoSrc}
                    alt="logo"
                    className={classNames(
                      "w-[96px] p-3 text-white"
                      // !isDarkMode && "invert"
                    )}
                  />
                </Link>
              </div>
            </div>}
            {!isMobile && <div className="flex items-center">
              <div>
                <Link to="/playground" className="mr-4 text-white sm:mr-6">
                  Text Chat
                </Link>
                <Link to="/voice-playground" className="mr-4 text-white sm:mr-6">
                  Voice Chat
                </Link>
              </div>
              {/* <div className="mr-2 sm:mr-6">
                <Button type="primary" icon={<FileAddOutlined />} onClick={() => {
                  // return navigate('/home');
                  if (user.activeSubscription && user.activeSubscription.status == 'active') {
                    return navigate('/home');
                  }
                  if (user.activeSubscription && user.activeSubscription.status != 'active') {
                    Modal.confirm({
                      title: 'Your subscription past due.',
                      icon: <ExclamationCircleFilled />,
                      content: 'Will you continue it?',
                      okText: "Yes",
                      cancelText: "No",
                      onOk() {
                        window.open(user.activeSubscription.hosted_invoice, '_blank');
                        return new Promise((resolve, reject) => {
                          setTimeout(resolve, 1000);
                        }).catch(() => console.log('Oops errors!'));
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    setOpenModal();
                  }
                }}>New Project</Button>
              </div> */}
              <UserMenu />
            </div>}
            {(isMobile && !isPayed) && <div className="leading-[0] w-52 mt-2.5">
              <p className="animate-shine text-center font-semibold m-0 text-lg">{config.steps[step]}</p>
              <Progress className='leading-[0] [&_.ant-progress-outer]:inline-flex' status="active" strokeLinecap="butt" percent={totalMessages / 40 * 100} showInfo={false} size={["100%", 8]} strokeColor="#3b0075" trailColor="#1b0035" />
            </div>}
          </div>
        </Header>
        {/* <div onClick={() => {
          navigate(-1);
        }} className={classNames("sticky w-[75px] cursor-pointer top-20 -ml-11 hover:ml-0 ease-in-out transition-[margin] z-[998] px-2.5 py-2 rounded-e-3xl", isDarkMode ? "bg-gray-100" : "bg-gray-300")}>
          <span>Back</span> &nbsp; <ArrowLeftOutlined />
        </div> */}
        <Layout /* className="h-[1000px]" */ className="bg-primary">
          {/* {location.pathname == '/voice-playground' && <AnimationBG />} */}
          {children}
        </Layout>
      </Layout>
      {/* <ScrollToTop smooth className="animate-bounce" /> */}
      {/* <FloatButton.BackTop /> */}
      {/* <Settings /> */}
      {/* <Drawer
        title="Basic Drawer"
        placement="left"
        closable={true}
        onClose={onClose}
        open={open}
      >
        <div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Get Advice</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Analyze Conversation</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Analyze Personality</p>
          </div>
          <div className="flex items-center">
            <img src="/imgs/logo.png" className="w-10 invert" alt="advice" />
            <p>Follow-Up Settings</p>
          </div>
        </div>
      </Drawer> */}
      <Elements stripe={stripePromise} nonce="random-nonce">
        <PayModal
          open={openPayModal}
          setOpen={() => dispatch(setOpenPayModal())}
          price={plans[0]?.price}
          planId={plans[0]?._id}
          setSuccessful={() => { }}
        />
      </Elements>
    </Layout>
  );
}

export default AuthLayout;
